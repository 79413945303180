<template>
  <v-container fluid>
    <v-card tile flat>
      <v-img contain height="100%" :src="selectedBooth.booth_thumbnail">
        <div v-for="(boothNotEmpty, i) in boothNotEmpties" :key="i">
          <router-link
            :to="{
              name: 'booth-edit',
              params: { id: boothNotEmpty.booth_coordinate.number_type },
            }"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                  class="tw-absolute tw-z-10 tw-cursor-pointer tw-bg-cover tw-bg-center"
                  :style="
                    `top: ${boothNotEmpty.booth_coordinate.top}%;
              left: ${boothNotEmpty.booth_coordinate.left}%;
              width: ${boothNotEmpty.booth_coordinate.width}%;
              height: ${boothNotEmpty.booth_coordinate.height}%;
              background: url('${boothNotEmpty.booth_image}');
              background-repeat: no-repeat;
              `
                  "
                ></div>
              </template>
              <span>Hint width & height</span>
            </v-tooltip>
          </router-link>
        </div>

        <div v-for="(boothEmpty, i) in boothEmpties" :key="'a' + i">
          <router-link
            :to="{ name: 'booth-edit', params: { id: boothEmpty.number_type } }"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                  class="tw-absolute tw-z-10 tw-cursor-pointer tw-border-2 tw-border-red-600 tw-border-solid"
                  :style="
                    `top: ${boothEmpty.top}%;
                    left: ${boothEmpty.left}%;
                    width: ${boothEmpty.width}%;
                    height: ${boothEmpty.height}%;`
                  "
                ></div>
              </template>
              <span>Hint width & height</span>
            </v-tooltip>
          </router-link>
        </div>
      </v-img>
    </v-card>
  </v-container>
</template>

<script>
import { coordinateByType, detailByTypes, detailBoothType } from "@/api/booth";
import { detail } from "@/api/developer";
export default {
  data() {
    return {
      boothEmpties: [],
      boothNotEmpties: [],
      numberType: [],
      selectedBooth: {},
      profile: {},
    };
  },
  async mounted() {
    await this.getDetailAccount();
    await this.getDetailBoothType();
    await this.getDetailBooth();
    await this.getCoordinateBooth();
  },
  computed: {
    vendorId() {
      return parseInt(this.$store.state.auth.profile.vendor_id);
    },
  },
  methods: {
    async getCoordinateBooth() {
      const {
        data: { data },
      } = await coordinateByType({
        booth_type_id: this.profile.booth_type_id,
        number_type: this.numberType,
      });

      this.boothEmpties = data;
    },
    async getDetailBooth() {
      const {
        data: { data },
      } = await detailByTypes({
        booth_type_id: this.profile.booth_type_id,
        developer_id: this.profile.id,
      });
  
      this.boothNotEmpties = data;
      data.forEach((item) => {
        this.numberType.push(item.booth_coordinate.number_type);
      });
    },
    async getDetailAccount() {
      const {
        data: { data },
      } = await detail(this.vendorId);
      const boothTypeId = parseInt(data.booth_type_id);
      this.profile = data;
      this.profile.booth_type_id = boothTypeId;
    },
    async getDetailBoothType() {
      const data = await detailBoothType({ id: this.profile.booth_type_id });

      this.selectedBooth = data;
    },
  },
};
</script>

<style></style>
