var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"tile":"","flat":""}},[_c('v-img',{attrs:{"contain":"","height":"100%","src":_vm.selectedBooth.booth_thumbnail}},[_vm._l((_vm.boothNotEmpties),function(boothNotEmpty,i){return _c('div',{key:i},[_c('router-link',{attrs:{"to":{
            name: 'booth-edit',
            params: { id: boothNotEmpty.booth_coordinate.number_type },
          }}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"tw-absolute tw-z-10 tw-cursor-pointer tw-bg-cover tw-bg-center",style:(("top: " + (boothNotEmpty.booth_coordinate.top) + "%;\n            left: " + (boothNotEmpty.booth_coordinate.left) + "%;\n            width: " + (boothNotEmpty.booth_coordinate.width) + "%;\n            height: " + (boothNotEmpty.booth_coordinate.height) + "%;\n            background: url('" + (boothNotEmpty.booth_image) + "');\n            background-repeat: no-repeat;\n            "))},'div',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("Hint width & height")])])],1)],1)}),_vm._l((_vm.boothEmpties),function(boothEmpty,i){return _c('div',{key:'a' + i},[_c('router-link',{attrs:{"to":{ name: 'booth-edit', params: { id: boothEmpty.number_type } }}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"tw-absolute tw-z-10 tw-cursor-pointer tw-border-2 tw-border-red-600 tw-border-solid",style:(("top: " + (boothEmpty.top) + "%;\n                  left: " + (boothEmpty.left) + "%;\n                  width: " + (boothEmpty.width) + "%;\n                  height: " + (boothEmpty.height) + "%;"))},'div',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("Hint width & height")])])],1)],1)})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }